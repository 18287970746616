<footer>
  <div [@animateFooter]>
    <ul class="footer-left-bar d-none d-md-block">
      <li>
        <a href="https://line.me/ti/p/~yasosa8888" target="_blank">
          <i class="fab fa-line"></i>
        </a>
      </li>
      <li>
        <a href="tel:+66902394886" target="_blank">
          <i class="fab fa-whatsapp"></i>
        </a>
      </li>
    </ul>
    <div class="footer-right-bar d-none d-md-block">
      <a
        (click)="
          analyticsService.sendAnalyticEvent(
            'click_send_mail',
            'footer',
            'email'
          )
        "
        href="mailto:tart.kreangkrai@gmail.com"
        target="_blank"
        >tart.kreangkrai@gmail.com
      </a>
    </div>
  </div>
</footer>
