import { Component, OnInit } from "@angular/core";

import {
  trigger,
  style,
  animate,
  transition,
  stagger,
  query,
} from "@angular/animations";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-banner",
  templateUrl: "./banner.component.html",
  styleUrls: ["./banner.component.scss"],
  animations: [
    trigger("bannerTrigger", [
      transition(":enter", [
        query("*", [
          style({ opacity: 0, transform: "translateX(-50px)" }),
          stagger(50, [
            animate(
              "250ms cubic-bezier(0.35, 0, 0.25, 1)",
              style({ opacity: 1, transform: "none" })
            ),
          ]),
        ]),
      ]),
    ]),
  ],
})
export class BannerComponent implements OnInit {
  count: number = 0;
  constructor(
    public analyticsService: AnalyticsService,
    private _http: HttpClient
  ) {}

  ngOnInit(): void {
    if (!sessionStorage.getItem("visited")) {
      this._http.get(`${environment.urlAPI}/viewCount`).subscribe({
        next: (res: any) => {
          console.log(res.data);
        },
      });
      sessionStorage.setItem("visited", "true");
    } else {
      // ผู้ใช้เคยเยี่ยมชมเว็บไซต์นี้แล้วในเซสชันปัจจุบัน
      console.log("ผู้ใช้กลับมาเยี่ยมชมในเซสชันเดียวกัน");
    }

    this._http.get(`${environment.urlAPI}/count`).subscribe({
      next: (res: any) => {
        this.count = res.data.amount;
      },
    });
  }

  downloadCV() {
    let url = window.location.href;
    window.open("assets/cv/kreangkraiEnTh.pdf", "_blank");
  }
}
