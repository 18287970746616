<section class="section contact" id="contact">
  <div class="container">
    <div class="section-box">
      <div class="mb-4" data-aos="fade-up" data-aos-duration="1000">
        <h3 class="contact-pre-title big-subtitle">
          05. {{ "Contact.Pretitle" | translate }}
        </h3>
      </div>
      <p data-aos="fade-up" data-aos-duration="1000">
        {{ "Contact.Content" | translate }}
      </p>

      <div class="mt-5">
        <a
          (click)="
            analyticsService.sendAnalyticEvent(
              'click_send_mail',
              'contact',
              'email'
            )
          "
          href="mailto:tart.kreangkrai@gmail.com"
          target="_blank"
          class="main-btn"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          Email
        </a>

        <a
          href="https://line.me/ti/p/~yasosa8888"
          target="_blank"
          class="main-btn2"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          Line
        </a>
        <a
          href="tel:+66902394886"
          target="_blank"
          class="main-btn2"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          Phone
        </a>
      </div>
    </div>

    <div class="fb-customerchat" page_id="409423994821318"></div>
  </div>
</section>
