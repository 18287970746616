<section class="section banner">
  <div [@bannerTrigger] class="container">
    <div class="showCount">
      <p>{{ count | number }} <span class="text-count">เข้าชม</span></p>
    </div>

    <div class="section-box-banner">
      <div class="content">
        <div>
          <h1>{{ "Banner.Pretitle" | translate }}</h1>
        </div>
        <div class="banner-title">
          <h2>{{ "Banner.Myname" | translate }}</h2>
          <h3>Full Stack Developer</h3>
        </div>
        <div class="banner-description">
          <p [innerHTML]="'Banner.Description' | translate" class="mt-4"></p>
        </div>
      </div>
      <div class="div-btn-banner">
        <a class="main-btn" (click)="downloadCV()">
          {{ "Banner.ActionBtn" | translate }}
        </a>
      </div>
    </div>
  </div>
</section>
